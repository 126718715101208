import React                   from 'react';
import { graphql }             from 'gatsby';
import { Col, Container, Row } from 'react-grid-system';
import Img                     from 'gatsby-image';

import Carousel      from '../components/Carousel';
import Wrapper       from '../components/Wrapper';
import Heading       from '../components/Heading';
import Spacer        from '../components/Spacer';
import Separator     from '../components/Seperator';
import Dropcap       from '../components/Dropcap';
import { findImage } from '../components/_helpers';
import BrandsBand    from '../components/BrandsBand';
import { J }         from '../components/Text';

import DefaultLayout from '../layouts/Default';

import CallToAction from '../modules/CallToAction';

class IndexPage extends React.Component {

  render() {
    return (
      <DefaultLayout location={this.props.location}>
        <Carousel>
          {/*Extract in helper component*/}
          {this.props.data.headerImages.edges.map((image) => (
            <Img key={image.node.name} fluid={image.node.childImageSharp.fluid} alt={image.node.name}/>
          ))}
        </Carousel>
        <Wrapper>
          <Spacer/>
          <Heading>Willkommen im Trauringstudio Solingen</Heading>
          <div>
            <J>Seien Sie herzlich willkommen im Trauringstudio Solingen, im Herzen der Klingenstadt. Unser
              Trauringstudio darf sich zu den bedeutendsten Trauringanbietern im Bergischen Land zählen.</J>
            <J>Das fein ausgewählte, vielseitige Sortiment wird ergänzt durch den TRAURING JUWELIER - KONFIGURATOR ,
              einem der leistungsstärksten Tools auf diesem Gebiet.</J>
            <J>Fernab der normalen Alltagshektik besprechen wir Ihre individuellen Trauring-Wünsche in aller Ruhe. Wir
              möchten Ihnen die „Lust auf Liebe“ in ungezwungener und angenehmer Atmosphäre vermitteln.</J>
          </div>
          <Separator/>
          <Wrapper width={1024}>
            <h2 style={{ textAlign: 'center' }}>Einladung zu unseren Trauringnächten</h2>
            <p style={{ textAlign: 'center', fontSize: '1.1em' }}>
              Findet Eure Trauringe in einer besonderen Atmosphäre bei Kerzenschein, Sekt und Fingerfood. <br/>
              So kann schon der Kauf Eurer Trauringe eine bleibende Erinnerung für Euch sein!
            </p>
            <Spacer height={30}/>
            <Container>
              <Row align={'center'}>
                <Col md={6}>
                  <p style={{ textAlign: 'center', fontSize: '1.2em' }}>Donnerstag, <br/>
                    07.02.19 - 18.00 - 22.00 Uhr</p>
                  <p style={{ textAlign: 'center', fontSize: '1.2em' }}>Dienstag, <br/>
                    12.02.19 - 18.00 - 22.00 Uhr</p>
                  <p style={{ textAlign: 'center', fontSize: '1.2em' }}>Mittwoch, <br/>
                    27.02.19 - 18.00 - 22.00 Uhr</p>
                </Col>
                <Col md={6}>
                  <Img style={{ height: '350px', maxWidth: '350px', margin: 'auto' }} fluid={this.props.data.promo.childImageSharp.fluid}
                       alt={'Trauringnacht'}/>
                </Col>
              </Row>
            </Container>
            <Spacer height={30}/>
            <p style={{ textAlign: 'center', fontSize: '1.1em' }}>
              Für Eure persönliche Beratung ist eine Terminvereinbarung unbedingt erforderlich! <br/>
              Telefon: 0212 / 204654
            </p>
          </Wrapper>
          <Separator/>
          <h2 style={{ textAlign: 'center' }}>So könnte der Besuch in unserem Trauringstudio aussehen...</h2>
          <div style={{ maxWidth: '640px', width: '95%', margin: 'auto', textAlign: 'center' }}>
            {/*eslint-disable-next-line*/}
            <iframe width="560" height="315"
                    src="https://www.youtube-nocookie.com/embed/CPlYPPfZKjY?rel=0&amp;showinfo=0" frameBorder="0"
                    allow="autoplay; encrypted-media" allowFullScreen></iframe>
          </div>
          <Separator/>
          <h2 style={{ textAlign: 'center' }}>Unsere Trauring-Marken</h2>
          <BrandsBand brands={this.props.data.brands.edges}/>
          <Separator/>
          <Heading>Von Mensch zu Mensch</Heading>
          <Container fluid>
            <Row>
              <Col md={6}>
                <Dropcap><h3>Annette Köster</h3></Dropcap>
                <J>Zu den angenehmsten Seiten unserer Tätigkeit gehört die Trauringberatung. Ich freue mich jedesmal
                  aufs Neue, wenn ich dazu beitragen darf, den Ring fürs Leben zu finden. Mein ganzes Wissen und meine
                  Erfahrung bringe ich ein, damit Sie die optimalen Ringe finden.</J>
              </Col>
              <Col md={6}>
                <Img fluid={findImage(this.props.data.teamImages, 'annette-koester').fluid} alt='Annette Köster'/>
              </Col>
            </Row>
            <Separator spacing={20}/>
            <Row>
              <Col md={6}>
                <Dropcap><h3>Andreas Köster</h3></Dropcap>
                <J>Als Mann mit "Einfühlungsvermögen" fasziniert mich die Emotionalität dieses Momentes. Sie finden
                  die
                  Ringe, die Sie Ihr ganzes Leben begleiten werden und Sie an einen der schönsten Tage im Leben, den
                  Tag
                  Ihrer Hochzeit, erinnern sollen. Mehr geht kaum.</J>
              </Col>
              <Col md={6}>
                <Img fluid={findImage(this.props.data.teamImages, 'andreas-koester').fluid} alt='Andreas Köster'/>
              </Col>
            </Row>
            <Separator spacing={20}/>
            <Row>
              <Col md={6}>
                <Dropcap><h3>Petra Zühlke</h3></Dropcap>
                <J>Mit Freude am Beruf, Spaß und immer guter Laune ist die Beratung beim Trauringkauf immer ein
                  Höhepunkt des Tages in meiner Tätigkeit. Langjährige Erfahrung und mein guter Geschmack wird auch
                  Ihnen bei Ihrer Entscheidung weiterhelfen.</J>
              </Col>
              <Col md={6}>
                <Img fluid={findImage(this.props.data.teamImages, 'petra-zuehlke').fluid} alt='Petra Zühlke'/>
              </Col>
            </Row>
            <Separator spacing={20}/>
            <Row>
              <Col md={6}>
                <Dropcap><h3>Svetlana Herrmann</h3></Dropcap>
                <J>Auch wenn ich in der Sprache meine kasachische Herkunft nicht leugnen kann, gebe ich mit Ruhe und
                  Geduld meine Erfahrung an Sie weiter. Die Orientierung an allem Wissenswerten über das Thema
                  Trauringe
                  macht mich zu einer kompetenten und überzeugenden Trauringberaterin, die Alles gibt, um mit Ihnen zu
                  Ihren Trau(m)ringen zu gelangen.</J>
              </Col>
              <Col md={6}>
                <Img fluid={findImage(this.props.data.teamImages, 'svetlana-herrmann').fluid}
                     alt='Svetlana Herrmann'/>
              </Col>
            </Row>
            <Separator spacing={20}/>
            <Row>
              <Col md={6}>
                <Dropcap><h3>Petra Niehues</h3></Dropcap>
                <J>Sie suchen unorthodoxe Ideen für Ihre Trauringe? Meine Kreativität als gelernte Goldschmiedin
                  versuche ich immer in Ihre Dienste zu stellen. Ruhig mal anders als die Anderen, mit Rat und Tat
                  werde
                  ich mit Ihnen dieses Ziel verfolgen und auch erreichen.</J>
              </Col>
              <Col md={6}>
                <Img fluid={findImage(this.props.data.teamImages, 'petra-niehues').fluid} alt='Petra Niehues'/>
              </Col>
            </Row>
          </Container>
          <Spacer/>
          <CallToAction/>
          <Spacer/>
        </Wrapper>
      </DefaultLayout>
    )
  }
}

export default IndexPage;

export const query = graphql`
  query IndexPageQuery {
    headerImages: allFile(filter: {relativeDirectory: {eq: "headers/index"}}) {
      edges {
        node {
          ...HeaderImages
        }
      }
    }
   promo: file(relativePath: {eq: "index/trauringnacht-img-1.jpg"}) {
    name
    childImageSharp {
      fluid(maxWidth: 250, quality: 85) {
        ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamImages: allFile(filter: {relativeDirectory: {eq: "team"}}) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 530, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    brands: allMarkdownRemark(filter: {frontmatter: {type: {eq: "brand"}}}, sort: {fields: [frontmatter___slug], order: ASC}) {
      edges {
        node {
          frontmatter {
            logoPath
            slug
            title
          }
        }
      }
    }
  }
`;
